// React Utils
import React from "react";

// Framer Motion Utils
import { motion } from "framer-motion";

// ShadcnUI Tabs
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../components/ui/tabs";

// Icons
import { IoMdCheckmark } from "react-icons/io";
import { HiOutlineBolt } from "react-icons/hi2";
import { BsLayers } from "react-icons/bs";
import { SlLayers } from "react-icons/sl";
import { MdKeyboardArrowRight } from "react-icons/md";

// Axios Util
import axiosInstance from "../../utils/axiosInstance";

// Razorpay Utils
import { useRazorpay } from "react-razorpay";
import { toast } from "react-toastify";

import { Link, useLocation } from "react-router-dom";

const PricingSection = () => {
  const { Razorpay } = useRazorpay();

  const access_token = localStorage.getItem("access_token"); // Authorization Token

  const handlePaymentCompletion = (payment_id, order_id, signature, plan) => {
    console.log(payment_id, order_id, signature);
    try {
      const response = axiosInstance.post(
        "razorpay/order/complete/",
        {
          order_id,
          payment_id,
          signature: "",
          plan,
          // amount,
        },
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json",
          },
        }
      );

      console.log(response);
    } catch (err) {
      console.log(err.response.data);
    }
  };

  const handlePayment = async (pricing, plan) => {
    console.log(plan, "PLAN");
    try {
      const response = await axiosInstance.post(
        "razorpay/order/create/",
        {
          plan, // Plan
          currency: "INR", // Currency in INR.
        },
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log(response);
      const { order_id } = response.data.data;
      console.log(order_id);

      const options = {
        key: "rzp_live_abcJK6HeMhwwKv",
        // name: "Test Company",
        currency: "INR",
        // amount: 1 * 100,
        // description: "Test Transaction",
        order_id: order_id, // Generate subscription_id on server
        handler: (response) => {
          console.log(response);
          //   alert("Payment Successful!");
          handlePaymentCompletion(
            response.razorpay_payment_id,
            order_id,
            response.razorpay_signature,
            plan
            // pricing * 100
          );
        },
        prefill: {
          name: "  ",
          email: "  ",
          contact: "+91-",
        },
        theme: {
          color: "#F37254",
        },
      };

      const razorpayInstance = new Razorpay(options);
      razorpayInstance.open();
      console.log(response);
    } catch (err) {
      console.log(err);
      if (err.response.status === 401) {
        toast.error("Please login/signup to buy a subscription", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };
  return (
    <div className="py-32 bg-black bg-[url('/src/assets/images/NewLandingPage/PricingBackground.png')] bg-top bg-no-repeat">
      <div className="flex flex-col items-center gap-20 px-20 max-sm:px-4">
        <motion.div
          initial={{
            opacity: 0,
            y: 25,
          }}
          whileInView={{
            opacity: 1,
            y: 0,
          }}
          transition={{
            duration: 0.7,
            ease: "easeOut",
            delay: 0,
          }}
          viewport={{
            once: true,
            margin: "-50px",
          }}
          className="flex flex-col gap-10"
        >
          <motion.h4
            className="font-sora font-bold text-5xl text-center text-white"
            initial={{ opacity: 0, y: 15 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.3 }}
            viewport={{ once: true }}
          >
            Pricing plans
          </motion.h4>

          <motion.p
            className="font-poppins text-sm text-center text-white"
            initial={{ opacity: 0, y: 10 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0 }}
            viewport={{ once: true }}
          >
            Flexible Plans Tailored to Your Legal Needs – Choose the Right
            Insights at the Right Price.
          </motion.p>
        </motion.div>
        <div>
          <div>
            <Tabs defaultValue="monthly" className="flex flex-col gap-10">
              <div className="flex items-center gap-4">
                <p className="font-inter font-bold text-xs text-white">
                  Save 15% <span className="font-normal">on yearly plan!</span>
                </p>
                <TabsList className="rounded-[100px] px-1 bg-white border border-[#e4e4e7]">
                  <TabsTrigger
                    className="rounded-[100px] data-[state=active]:bg-black data-[state=active]:text-white font-inter font-semibold text-sm px-4"
                    value="monthly"
                  >
                    Monthly
                  </TabsTrigger>
                  <TabsTrigger
                    className="rounded-[100px] data-[state=active]:bg-black data-[state=active]:text-white font-inter font-semibold text-sm px-4"
                    value="yearly"
                  >
                    Yearly
                  </TabsTrigger>
                </TabsList>
              </div>
              <TabsContent value="monthly">
                <div className="flex gap-6 max-md:flex-col">
                  <PricingCard
                    handlePayment={handlePayment}
                    subscriptionName={"Case Tracker"}
                    subscriptionPrice={999}
                    timeDuration={"month"}
                    features={[
                      "Case Management: Handle up to 50 cases efficiently with ease.",
                      "Basic Case Tracking: Keep track of case statuses and progress with fundamental tracking features.",
                      "Real-Time Notifications: Stay informed with timely alerts and updates.",
                      "Regular Updates: Receive essential updates to keep your cases on track.",
                      "Case-Specific Chatbot: Leverage a chatbot tailored to individual case requirements.",
                      "Multi-Language Support: Communicate seamlessly with clients in multiple languages.",
                    ]}
                    icon={<HiOutlineBolt />}
                    paymentSubName={"tracker"}
                  />
                  <PricingCard
                    handlePayment={handlePayment}
                    subscriptionName={"Professional"}
                    subscriptionPrice={1999}
                    timeDuration={"month"}
                    features={[
                      "Unlimited Case Management: Manage cases without limitations to ensure scalability.",
                      "AI-Driven Chatbot Queries: Address up to 500 client inquiries with automated efficiency.",
                      "Basic Reporting Features: Access essential reports for monitoring and decision-making.",
                      "Case Tracking System: Stay updated on case progress with streamlined tracking tools.",
                      "Case-Specific Chatbot: Leverage a chatbot tailored to individual case requirements.",
                      "Multi-Language Support: Communicate seamlessly with clients in multiple languages.",
                    ]}
                    middle={true}
                    icon={<BsLayers />}
                    paymentSubName={"professional"}
                  />
                  <PricingCard
                    handlePayment={handlePayment}
                    subscriptionName={"Business"}
                    subscriptionPrice={4999}
                    timeDuration={"month"}
                    features={[
                      "Unlimited Case Management: Manage cases without limitations to ensure scalability.",
                      "Unlimited Chatbot Interactions: Empower clients with unlimited AI-driven chatbot support for instant query resolution",
                      "Advanced Reporting: Access detailed reports with insights to optimize decision-making and operational efficiency.",
                      "Comprehensive Case Analytics: Leverage analytics to gain actionable insights into case progress and outcomes.",
                      "Collaborative Tools: Enhance teamwork with tools designed for seamless collaboration across teams.",
                      "Multi-Language Support: Communicate seamlessly with clients in multiple languages.",
                    ]}
                    icon={<SlLayers />}
                    paymentSubName={"business"}
                  />
                </div>
              </TabsContent>

              <TabsContent value="yearly">
                <div className="flex gap-6 max-md:flex-col">
                  <PricingCard
                    handlePayment={handlePayment}
                    subscriptionName={"Case Tracker"}
                    subscriptionPrice={9999}
                    timeDuration={"yearly"}
                    features={[
                      "Case Management: Handle up to 50 cases efficiently with ease.",
                      "Basic Case Tracking: Keep track of case statuses and progress with fundamental tracking features.",
                      "Real-Time Notifications: Stay informed with timely alerts and updates.",
                      "Regular Updates: Receive essential updates to keep your cases on track.",
                      "Case-Specific Chatbot: Leverage a chatbot tailored to individual case requirements.",
                      "Multi-Language Support: Communicate seamlessly with clients in multiple languages.",
                    ]}
                    icon={<HiOutlineBolt />}
                    paymentSubName={"yearly_tracker"}
                  />
                  <PricingCard
                    handlePayment={handlePayment}
                    subscriptionName={"Professional"}
                    subscriptionPrice={19999}
                    timeDuration={"yearly"}
                    features={[
                      "Unlimited Case Management: Manage cases without limitations to ensure scalability.",
                      "AI-Driven Chatbot Queries: Address up to 500 client inquiries with automated efficiency.",
                      "Basic Reporting Features: Access essential reports for monitoring and decision-making.",
                      "Case Tracking System: Stay updated on case progress with streamlined tracking tools.",
                      "Case-Specific Chatbot: Leverage a chatbot tailored to individual case requirements.",
                      "Multi-Language Support: Communicate seamlessly with clients in multiple languages.",
                    ]}
                    middle={true}
                    icon={<BsLayers />}
                    paymentSubName={"yearly_professional"}
                  />
                  <PricingCard
                    handlePayment={handlePayment}
                    subscriptionName={"Business"}
                    subscriptionPrice={49999}
                    timeDuration={"yearly"}
                    features={[
                      "Unlimited Case Management: Manage cases without limitations to ensure scalability.",
                      "Unlimited Chatbot Interactions: Empower clients with unlimited AI-driven chatbot support for instant query resolution",
                      "Advanced Reporting: Access detailed reports with insights to optimize decision-making and operational efficiency.",
                      "Comprehensive Case Analytics: Leverage analytics to gain actionable insights into case progress and outcomes.",
                      "Collaborative Tools: Enhance teamwork with tools designed for seamless collaboration across teams.",
                      "Multi-Language Support: Communicate seamlessly with clients in multiple languages.",
                    ]}
                    icon={<SlLayers />}
                    paymentSubName={"yearly_business"}
                  />
                </div>
              </TabsContent>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
};

const PricingCard = ({
  handlePayment,
  subscriptionName,
  subscriptionPrice,
  timeDuration,
  features,
  middle,
  icon,
  paymentSubName,
}) => {
  const location = useLocation();

  return (
    <div
      className={`${
        middle
          ? "bg-[#1d1d1d] text-white border-[#1d1d1d] bg-[url('/src/assets/images/NewLandingPage/PricingProfressionalBG.png')] bg-[10%_40%]"
          : "bg-white border-[#e4e4e7]"
      } border max-w-[384px] px-6 py-10 rounded-lg flex flex-1 min-w-0 flex-col gap-12 hover:-translate-y-6 max-sm:hover:-translate-y-3 transition-all duration-300`}
    >
      <div className={`flex flex-col gap-4 text-[#18181b]`}>
        <div className="flex flex-col items-center gap-2">
          <div
            className={`${
              middle ? "bg-white text-black" : "bg-black text-white"
            } size-12 bg-black mb-4 flex justify-center items-center rounded-full text-2xl`}
          >
            {icon}
          </div>
          <p
            className={`${
              middle && "text-white"
            } font-poppins font-bold text-2xl`}
          >
            {subscriptionName}
          </p>
          <p
            className={`${
              middle && "text-white"
            } font-poppins text-sm text-center`}
          >
            Get started for free by using our 7 day free trial
          </p>
        </div>
        <p
          className={`${
            middle && "text-white"
          } mt-6 justify-center font-poppins font-extrabold text-5xl flex items-center gap-0.5`}
        >
          ₹ {subscriptionPrice}
          <span className="text-base mt-4 tracking-tight">/{timeDuration}</span>
        </p>
      </div>
      <div>
        <button
          // href="/signup"
          // target="_blank"
          onClick={() => handlePayment(subscriptionPrice, paymentSubName)}
          className="font-poppins block font-semibold bg-[#521EDF] text-white w-full text-center py-3 rounded-[38px]"
        >
          Get Started
        </button>
      </div>
      <div
        className={`flex flex-col h-full justify-between gap-5 dark:text-black ${
          middle && "dark:text-white"
        }`}
      >
        <p className="font-poppins font-semibold text-lg ">What you get:</p>
        {location.pathname === "/" ? (
          <>
            {features?.slice(0, 3).map((feature, index) => (
              <div
                className="flex flex-1 gap-4 items-center font-poppins text-sm"
                key={index}
              >
                <IoMdCheckmark
                  className={`${
                    middle ? "text-white" : "text-[#521EDF]"
                  } text-xl flex-shrink-0`}
                />
                <p>{feature}</p>
              </div>
            ))}
            <div className="self-end">
              <Link
                to="/pricing"
                className={`flex gap-1 items-center text-[#521EDF] font-medium ${
                  middle && "text-white"
                }`}
              >
                View More <MdKeyboardArrowRight className="text-3xl mt-0.5" />
              </Link>
            </div>
          </>
        ) : (
          features?.map((feature, index) => (
            <div
              className="flex flex-1 gap-4 items-center font-poppins text-sm"
              key={index}
            >
              <IoMdCheckmark
                className={`${
                  middle ? "text-white" : "text-[#521EDF]"
                } text-xl flex-shrink-0`}
              />
              <p>{feature}</p>
            </div>
          ))
        )}
        {}
      </div>
    </div>
  );
};

export default PricingSection;
