import React from "react";

const JudgeDetailsDC = () => {
  return (
    <div className="pt-20">
      <div className="flex flex-col">
        <div>Detail 1</div>
        <div>Detail 2</div>
      </div>
    </div>
  );
};

export default JudgeDetailsDC;
