import React from "react";

const JudgeAnalyticsDC = () => {
  return (
    <div className="pt-20">
      <div className="flex flex-col">
        <div>Section 1</div>
        <div>Section 2</div>
      </div>
    </div>
  );
};

export default JudgeAnalyticsDC;
